import React from 'react';

import Layout from '../../components/Layout';

export default function Sozialdienst() {
	return (
		<Layout title="Sozialdienst">
			<h2>Sozialdienst</h2>
			<p>
				<ul>
					<li>Organisation von Ausflügen für Senioren und Menschen mit Behinderung</li>
					<li>Betreuung von Blutspendediensten</li>
					<li>Angebot von Seniorengymnastik</li>
					<li>Angebot von Besucherdiensten für Senioren</li>
				</ul>
			</p>
		</Layout>
	);
}
